import React from 'react'
import { Container, Text } from './styles'

export const Loading: React.FC = () => (
  <Container>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.369" height="21.294" viewBox="0 0 21.369 21.294">
      <path id="Caminho_14" data-name="Caminho 14" d="M16.314.1H5.255A5.247,5.247,0,0,0,.1,5.4V16.089a5.247,5.247,0,0,0,5.155,5.3H16.314a5.247,5.247,0,0,0,5.155-5.3V5.48A5.265,5.265,0,0,0,16.314.1M4.135,13.325C1.893,10.785,2.043,4.957,5.928,3.089c1.943-.9,3.885.971,2.765,2.391a34.357,34.357,0,0,0-4.558,7.845m1.27,0c.374-.822,2.914-6.052,5.454-8.518,2.69-2.69,8.368,2.989,5.678,5.678C14,13.026,8.767,15.566,8.02,15.94,6.75,16.463,4.882,14.6,5.4,13.325m12.777,2.092C16.388,19.3,10.56,19.452,8.02,17.21a35.863,35.863,0,0,0,7.845-4.483c1.42-1.2,3.213.822,2.316,2.69" transform="translate(-0.1 -0.1)" fill="#54b8f4"/>
    </svg>
    <Text>Loading...</Text>
  </Container>
)
