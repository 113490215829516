import React, { ChangeEvent, FormEventHandler, useState } from 'react'
import { useTheme } from 'styled-components'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'

import { formatDistanceToNow, parseISO } from 'date-fns'

import { Button } from '../../../../common/components'
import useAuth from '../../../../hooks/useAuth'
import { PostInterface } from '../../../../interfaces'
import {
  Avatar,
  CommentAvatar,
  CommentContainer,
  CommentIcon,
  CommentInput,
  CommentPlaceholder,
  CommentsWrapper,
  CommentToggle,
  CommentValue,
  Container,
  Content,
  ContentArticle,
  ContentHeader,
  ContentSocial,
  ContentWrapper,
  DateTime,
  LikeButton,
  LikeCount,
  LikedIconContainer,
  Placeholder,
  PostImage,
  PostText,
  PostTitle,
  PostUserName,
  PostUserTitle,
  SocialContainer,
  SocialCount
} from './styles'
import useComments from '../../hooks/useComments'
import { useNavigate } from 'react-router-dom'
import useFeed from '../../hooks/useFeed'

export const Post: React.FC<PostInterface> = ({
  id,
  data,
  postedBy,
  createdAt,
  isArticle,
  commentsFindAllResponseDto
}) => {
  const [comment, setComment] = useState<string>('')
  const { currentUser } = useAuth()
  const theme = useTheme()
  const navigate = useNavigate()

  const { refetch } = useFeed()

  const [liked, setLiked] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(false)

  const { loading, create, handleStopLoading } = useComments()

  const handleAddComment: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    if (!comment) return

    create({
      postId: id,
      text: comment
    }).then(() => {
      setComment('')
      // navigate('/feed')
      window.location.reload()
    }).finally(() => {
      handleStopLoading()
    })
  }

  // function renderHTML() {
  //   return { __html: data.text }
  // }

  return (
    <Container>
      <Content>
        {postedBy.photoUrl
          ? (
          <Avatar src={postedBy.photoUrl} alt={postedBy.name} />
            )
          : (
         <Placeholder>{postedBy.name[0]}</Placeholder>
            )}
        <ContentWrapper>
          <ContentHeader>
            <div>
              <PostUserName>{postedBy.name}</PostUserName>
              <PostUserTitle>{postedBy.title}</PostUserTitle>
            </div>
            {!!createdAt && <DateTime>{formatDistanceToNow(parseISO(createdAt))}</DateTime>}
          </ContentHeader>
          <ContentArticle>
            {!!data.title && <PostTitle>{data.title}</PostTitle>}
            {!!data.text && <PostText><p>{data.text}</p></PostText>}
            {!!data.image && <PostImage src={data.image} />}
          </ContentArticle>
          <ContentSocial>
            <LikeButton onClick={() => setLiked(!liked)}>
              {liked
                ? (
                <LikedIconContainer>
                  <AiFillHeart color={theme.palette.font.active} size={17} />
                </LikedIconContainer>
                  )
                : (
                  <AiOutlineHeart color={theme.palette.font.primary} size={17} />
                  )}
                  <span>Like</span>
            </LikeButton>
            <SocialCount>
              <CommentToggle onClick={() => setExpanded(!expanded)}>
                {!!commentsFindAllResponseDto?.length && `${commentsFindAllResponseDto.length} comment${commentsFindAllResponseDto.length > 1 ? 's' : ''}`}
              </CommentToggle>
              {liked && <LikeCount>{liked ? '1' : '0'} people liked this {isArticle ? 'article' : 'post'}</LikeCount>}
            </SocialCount>
          </ContentSocial>
        </ContentWrapper>
      </Content>
      <SocialContainer>
        {currentUser?.photoUrl
          ? (
          <Avatar src={currentUser?.photoUrl} alt={currentUser?.name as string} />
            )
          : (
          <Placeholder>{currentUser?.name?.[0]}</Placeholder>
            )}
        <form onSubmit={handleAddComment}>
          <CommentInput
            value={comment}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
            placeholder='Add a comment'
          />
          <CommentIcon size={18} color={theme.palette.font.primary} />
          <Button variant='primary' disabled={loading || !comment} type="submit">Send</Button>
        </form>
      </SocialContainer>
      {expanded && (
        <CommentsWrapper>
          {commentsFindAllResponseDto?.map(item => (
            <CommentContainer key={item.commendId}>
              {item.postedByDto.photoUrl
                ? (
                <CommentAvatar src={item.postedByDto.photoUrl} />
                  )
                : (
                <CommentPlaceholder>{item.postedByDto.name[0]}</CommentPlaceholder>
                  )}
                  <CommentValue>
                    <p>{item.text}</p>
                    <span>{formatDistanceToNow(parseISO(item.createdAt))}</span>
                  </CommentValue>
            </CommentContainer>
          ))}
        </CommentsWrapper>
      )}
    </Container>
  )
}
