import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { useFormik } from 'formik'
import * as yup from 'yup'

import useEditProfile from '../../hooks/useEditProfile'
import useAuth from '../../../../hooks/useAuth'

import {
  Container,
  Content,
  Form,
  Header,
  Title,
  Wrapper
} from './styles'
import { Button, Loading, TextField } from '../../../../common/components'
import { useNavigate } from 'react-router-dom'

export const EditProfile: React.FC = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { data, loading, update } = useEditProfile(currentUser?.id as number)

  console.log(data)

  const initialValues = {
    name: data?.name || '',
    slackUser: data?.slackUser || '',
    currentProject: data?.currentProject || '',
    title: data?.title || ''
  }

  const schema = yup.object().shape({
    name: yup.string().required('This field is required').trim(),
    slackUser: yup.string().trim(),
    currentProject: yup.string().trim(),
    title: yup.string().trim()
  })

  const {
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleSubmit,
    getFieldProps
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: profile => {
      update({
        name: profile.name,
        slackUser: profile.slackUser,
        currentProject: profile.currentProject,
        title: profile.title
      }).then(() => {
        window.location.reload()
        navigate('/feed')
      })
    }
  })

  return (
    <Wrapper>
      <Container>
        <Header>
          <Title>Edit profile</Title>
        </Header>
        {loading && <Loading />}
        {!loading && (
          <Content>
            <Form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                error={errors.name && touched.name ? errors.name : ''}
                {...getFieldProps('name')}
              />
              <TextField
                label="Slack User"
                error={errors.slackUser && touched.slackUser ? errors.slackUser : ''}
                {...getFieldProps('slackUser')}
              />
              <TextField
                label="Title"
                error={errors.title && touched.title ? errors.title : ''}
                {...getFieldProps('title')}
              />
              <TextField
                label="Current project"
                error={errors.currentProject && touched.currentProject ? errors.currentProject : ''}
                {...getFieldProps('currentProject')}
              />
              <div>
                <Button type='submit' variant='primary'>Save</Button>
              </div>
            </Form>
          </Content>
        )}
      </Container>
    </Wrapper>
  )
}
