import { useState, useEffect } from 'react'
import api from '../../../api'
import { AxiosResponse } from 'axios'
import useAuth from '../../../hooks/useAuth'
import { PostInterface } from '../../../interfaces'

interface PostPayload {
  title: string,
  text: string,
  image: string,
  isArticle: boolean
}

interface SearchReturn {
  data: PostInterface[] | null
  loading: boolean
  error: string | null
  create: ({
    title,
    text,
    image,
    isArticle
  }:PostPayload) => Promise<AxiosResponse<any, any>>
  refetch: () => void
}

function useFeed(): SearchReturn {
  const [data, setData] = useState<PostInterface[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const { currentUser } = useAuth()

  const query = () => {
    api.get<PostInterface[]>('/post/all')
      .then(response => {
        const { data } = response
        setData(data)
      })
      .catch(err => {
        setError(JSON.stringify(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const create = async ({
    title,
    text,
    image,
    isArticle
  }: PostPayload) => {
    const payload = JSON.stringify({
      title,
      text,
      image,
      isArticle
    })
    return api.post(`/${currentUser?.id}/post`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  useEffect(() => {
    query()
  }, [])

  return {
    data,
    loading,
    error,
    create,
    refetch: query
  }
}

export default useFeed
