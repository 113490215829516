import styled, { keyframes } from 'styled-components'

const pulsing = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.65;
  }
`

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${pulsing} 0.3s alternate;
  row-gap: 1rem;
`

export const Text = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.font.primary};
`
