import { useState } from 'react'

import api from '../../../api'
import useAuth from '../../../hooks/useAuth'

interface CommentCreationProps {
  text: string
  postId: number
  userCommentedId?: number
}

const useComments = () => {
  const { currentUser } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const handleStopLoading = () => {
    setLoading(false)
  }

  const create = (props: CommentCreationProps) => {
    setLoading(true)
    const payload = JSON.stringify({
      postId: props.postId,
      text: props.text,
      image: '',
      ...props.userCommentedId && { userCommentedId: props.userCommentedId }
    })
    return api.post(`/comment/${currentUser?.id}`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  return {
    create,
    loading,
    handleStopLoading
  }
}

export default useComments
