import React from 'react'

export const MainLogo: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="265.772" height="44.574" viewBox="0 0 265.772 44.574">
      <g id="bairesbook-logo-blue" transform="translate(-0.1 -0.1)">
        <path id="Caminho_1" data-name="Caminho 1" d="M34.039.1H10.892A10.983,10.983,0,0,0,.1,11.2V33.57a10.983,10.983,0,0,0,10.792,11.1H34.039A10.983,10.983,0,0,0,44.83,33.57V11.361A11.021,11.021,0,0,0,34.039.1M8.546,27.783C3.854,22.465,4.166,10.266,12.3,6.356c4.066-1.877,8.133,2.033,5.787,5a71.916,71.916,0,0,0-9.54,16.422m2.659,0c.782-1.72,6.1-12.668,11.417-17.83,5.63-5.63,17.517,6.256,11.886,11.886-5.318,5.318-16.266,10.635-17.83,11.417-2.659,1.095-6.569-2.815-5.474-5.474m26.744,4.379C34.2,40.295,22,40.608,16.678,35.916A75.069,75.069,0,0,0,33.1,26.532c2.972-2.5,6.725,1.72,4.848,5.63" transform="translate(0 0)" fill="#0272b1"/>
        <path id="Caminho_2" data-name="Caminho 2" d="M49.3,18.494q3.754-1.642,3.754-6.1A6.351,6.351,0,0,0,50.7,7.077,10.744,10.744,0,0,0,43.979,5.2H33.5V33.978H44.6a11.438,11.438,0,0,0,7.351-2.19,7.478,7.478,0,0,0,2.659-6.1,7.787,7.787,0,0,0-1.408-4.536,9.686,9.686,0,0,0-3.91-2.659M37.879,9.11H43.2a6.013,6.013,0,0,1,3.754.938A3.436,3.436,0,0,1,48.2,12.864a4.01,4.01,0,0,1-1.251,3.284,6.017,6.017,0,0,1-4.066,1.095h-5V9.11ZM48.2,28.973a5.828,5.828,0,0,1-3.91,1.095H37.879V21h5.943c3.754,0,5.787,1.564,5.787,4.536A4.154,4.154,0,0,1,48.2,28.973" transform="translate(18.838 2.876)" fill="#0272b1"/>
        <path id="Caminho_3" data-name="Caminho 3" d="M64.24,14.228a5.522,5.522,0,0,0-2.659-2.346,7.365,7.365,0,0,0-3.754-.782,9.167,9.167,0,0,0-4.848,1.408,9.558,9.558,0,0,0-3.284,3.754,11.122,11.122,0,0,0-1.095,5,11.6,11.6,0,0,0,1.251,5.318,9.114,9.114,0,0,0,3.284,3.6,9.554,9.554,0,0,0,4.692,1.251c3.128,0,5.318-1.095,6.725-3.284v2.659h4.223V11.569H64.553ZM62.832,25.489a6.043,6.043,0,0,1-8.446,0,6.17,6.17,0,0,1-1.564-4.536,6.417,6.417,0,0,1,1.564-4.536,6.043,6.043,0,0,1,8.446,0A6.17,6.17,0,0,1,64.4,20.953a6.9,6.9,0,0,1-1.564,4.536" transform="translate(27.354 6.204)" fill="#0272b1"/>
        <rect id="Retângulo_4" data-name="Retângulo 4" width="4.379" height="19.081" transform="translate(100.352 17.773)" fill="#0272b1"/>
        <path id="Caminho_4" data-name="Caminho 4" d="M66.559,5.1A2.67,2.67,0,0,0,63.9,7.759a2.659,2.659,0,1,0,4.536-1.877A2.59,2.59,0,0,0,66.559,5.1" transform="translate(35.983 2.82)" fill="#0272b1"/>
        <path id="Caminho_5" data-name="Caminho 5" d="M79.7,11.2a5.782,5.782,0,0,0-3.284.938,5.339,5.339,0,0,0-2.19,2.659V11.513H70V30.594h4.379V21.679a7.979,7.979,0,0,1,1.251-4.692,4.241,4.241,0,0,1,3.6-1.72,5,5,0,0,1,2.19.469l.469-4.066a9.967,9.967,0,0,0-1.095-.313A3.054,3.054,0,0,0,79.7,11.2" transform="translate(39.423 6.26)" fill="#0272b1"/>
        <path id="Caminho_6" data-name="Caminho 6" d="M92.489,12.095A9.059,9.059,0,0,0,87.8,11a8.93,8.93,0,0,0-5,1.408A9.5,9.5,0,0,0,79.351,16,11.43,11.43,0,0,0,78.1,21.166a10.566,10.566,0,0,0,1.408,5.474,8.836,8.836,0,0,0,3.754,3.441,11.13,11.13,0,0,0,5.318,1.251,12.2,12.2,0,0,0,3.441-.469A10.261,10.261,0,0,0,95.3,29.612l-.469-3.441a13.277,13.277,0,0,1-5.943,1.408,5.989,5.989,0,0,1-4.223-1.408,5.8,5.8,0,0,1-2.19-3.754H96.555a6.5,6.5,0,0,0,.156-2.033,10.268,10.268,0,0,0-1.095-5,7.919,7.919,0,0,0-3.128-3.284m-10.01,7.194a5.255,5.255,0,0,1,1.72-3.6,5.153,5.153,0,0,1,3.441-1.251,4.869,4.869,0,0,1,3.441,1.251,5.845,5.845,0,0,1,1.408,3.6Z" transform="translate(43.992 6.148)" fill="#0272b1"/>
        <path id="Caminho_7" data-name="Caminho 7" d="M100.2,19.133a20.46,20.46,0,0,1-2.346-.782c-.469-.313-.938-.469-1.095-.782a2.261,2.261,0,0,1-.313-1.095,1.5,1.5,0,0,1,.938-1.408,5.484,5.484,0,0,1,2.5-.469,8.856,8.856,0,0,1,2.815.469,13.53,13.53,0,0,1,2.659,1.095l.626-3.6a9.6,9.6,0,0,0-2.815-1.095A12.2,12.2,0,0,0,99.733,11a8.441,8.441,0,0,0-5.474,1.564,5.044,5.044,0,0,0-2.033,4.223,4.712,4.712,0,0,0,1.408,3.6,10.878,10.878,0,0,0,4.692,2.346c1.095.313,1.877.626,2.5.782a4.33,4.33,0,0,1,1.251.782c.313.313.313.626.313,1.251,0,1.408-1.251,2.19-3.91,2.19a9.264,9.264,0,0,1-3.284-.626A10.373,10.373,0,0,1,92.226,25.7L91.6,29.3a7.418,7.418,0,0,0,2.972,1.251,15.756,15.756,0,0,0,3.91.469,9.891,9.891,0,0,0,5.943-1.564,5.193,5.193,0,0,0,2.19-4.223,4.637,4.637,0,0,0-1.564-3.754,13.788,13.788,0,0,0-4.848-2.346" transform="translate(51.606 6.148)" fill="#0272b1"/>
        <path id="Caminho_11" data-name="Caminho 11" d="M19.908-24.047c0,2.075-1.37,3.155-3.736,3.155h-5.23v-6.434h5.147C18.455-27.326,19.908-26.122,19.908-24.047Zm-.706-11.5c0,1.992-1.287,3.03-3.611,3.03H10.942v-6.1h4.649C17.915-38.616,19.2-37.537,19.2-35.544Zm7.887,12.453a7.066,7.066,0,0,0-5.6-7.015,6.611,6.611,0,0,0,4.939-6.641c0-4.607-3.528-7.555-9.464-7.555H3.844v29.139H17.417C23.56-15.164,27.089-18.277,27.089-23.092Zm26.773-3.653c0-7.347-5.23-11.913-12.037-11.913-6.766,0-12.037,4.566-12.037,11.913s5.147,11.913,11.954,11.913S53.862-19.4,53.862-26.745Zm-16.852,0c0-3.943,2.241-5.77,4.815-5.77,2.491,0,4.815,1.826,4.815,5.77,0,3.9-2.366,5.77-4.9,5.77C39.168-20.975,37.009-22.843,37.009-26.745Zm43.293,0c0-7.347-5.23-11.913-12.037-11.913-6.766,0-12.037,4.566-12.037,11.913s5.147,11.913,11.954,11.913S80.3-19.4,80.3-26.745Zm-16.852,0c0-3.943,2.241-5.77,4.815-5.77,2.49,0,4.815,1.826,4.815,5.77,0,3.9-2.366,5.77-4.9,5.77C65.608-20.975,63.45-22.843,63.45-26.745ZM84.08-15.164h7.1v-9.713l7.056,9.713h8.8L97.321-26.7l9.63-11.622H98.193L91.178-28.9V-45.88h-7.1Z" transform="translate(158.838 52.139)" fill="#54b8f4"/>
      </g>
    </svg>
  )
}
