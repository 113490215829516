import styled from 'styled-components'

export const Page = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1.5rem;

  background: linear-gradient(to right bottom, #effcff, #d7eaeb);
`
