import { useState, useEffect } from 'react'
import api from '../../../api'
import useAuth from '../../../hooks/useAuth'
import { User } from '../../../interfaces'
interface ProfileUpdate {
  name?: string
  birthDate?: string
  slackUser?: string
  startDate?: string
  title?: string
  state?: string
  country?: string
  currentProject?: string
}

function useEditProfile(userId: number) {
  const [data, setData] = useState<User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const { currentUser } = useAuth()

  const query = () => {
    api.get<User>(`/user/${userId}`)
      .then(response => {
        const { data } = response
        setData(data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = (props: ProfileUpdate) => {
    const payload = JSON.stringify({
      ...!!props.name && { name: props.name },
      ...!!props.birthDate && { birthDate: props.birthDate },
      ...!!props.slackUser && { slackUser: props.slackUser },
      ...!!props.startDate && { startDate: props.startDate },
      ...!!props.title && { title: props.title },
      ...!!props.state && { state: props.state },
      ...!!props.country && { country: props.country },
      ...!!props.currentProject && { currentProject: props.currentProject }
    })
    return api.put(`/user/${currentUser?.id}`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  useEffect(() => {
    query()
  }, [userId])

  return {
    data,
    loading,
    update
  }
}

export default useEditProfile
