import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-right: -0.75rem;
  margin-left: -0.75rem;

  & > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-top: 0;
  }
`

export const Column = styled.div`
  flex: 1 0 0%;
`
